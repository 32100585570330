<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UNotifications />
</template>

<script setup lang="ts">
useSeoMeta({
  title: "CryptoXChange - Gérer ses cryptos et ses actifs",
  ogTitle: "CryptoXChange - Gérer ses cryptos et ses actifs",
  description: "This is my amazing site, let me tell you all about it.",
  ogDescription: "This is my amazing site, let me tell you all about it.",
  ogImage: "https://example.com/image.png",
  twitterCard: "summary_large_image",
});
</script>
