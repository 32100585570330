import { default as accountsyiSMT60z7sMeta } from "/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/accounts.vue?macro=true";
import { default as indexvqKdnirNGsMeta } from "/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/index.vue?macro=true";
import { default as logoutddR3PRCzSOMeta } from "/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/logout.vue?macro=true";
import { default as parameterstDYXKqahGqMeta } from "/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/parameters.vue?macro=true";
import { default as portfoliosNUgOurEWKjMeta } from "/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/portfolios.vue?macro=true";
import { default as pricesTwcjzqIzWYMeta } from "/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/prices.vue?macro=true";
import { default as profilesQxR08u5LbMeta } from "/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/profile.vue?macro=true";
export default [
  {
    name: accountsyiSMT60z7sMeta?.name ?? "accounts",
    path: accountsyiSMT60z7sMeta?.path ?? "/accounts",
    meta: accountsyiSMT60z7sMeta || {},
    alias: accountsyiSMT60z7sMeta?.alias || [],
    redirect: accountsyiSMT60z7sMeta?.redirect || undefined,
    component: () => import("/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/accounts.vue").then(m => m.default || m)
  },
  {
    name: indexvqKdnirNGsMeta?.name ?? "index",
    path: indexvqKdnirNGsMeta?.path ?? "/",
    meta: indexvqKdnirNGsMeta || {},
    alias: indexvqKdnirNGsMeta?.alias || [],
    redirect: indexvqKdnirNGsMeta?.redirect || undefined,
    component: () => import("/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logoutddR3PRCzSOMeta?.name ?? "logout",
    path: logoutddR3PRCzSOMeta?.path ?? "/logout",
    meta: logoutddR3PRCzSOMeta || {},
    alias: logoutddR3PRCzSOMeta?.alias || [],
    redirect: logoutddR3PRCzSOMeta?.redirect || undefined,
    component: () => import("/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: parameterstDYXKqahGqMeta?.name ?? "parameters",
    path: parameterstDYXKqahGqMeta?.path ?? "/parameters",
    meta: parameterstDYXKqahGqMeta || {},
    alias: parameterstDYXKqahGqMeta?.alias || [],
    redirect: parameterstDYXKqahGqMeta?.redirect || undefined,
    component: () => import("/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/parameters.vue").then(m => m.default || m)
  },
  {
    name: portfoliosNUgOurEWKjMeta?.name ?? "portfolios",
    path: portfoliosNUgOurEWKjMeta?.path ?? "/portfolios",
    meta: portfoliosNUgOurEWKjMeta || {},
    alias: portfoliosNUgOurEWKjMeta?.alias || [],
    redirect: portfoliosNUgOurEWKjMeta?.redirect || undefined,
    component: () => import("/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/portfolios.vue").then(m => m.default || m)
  },
  {
    name: pricesTwcjzqIzWYMeta?.name ?? "prices",
    path: pricesTwcjzqIzWYMeta?.path ?? "/prices",
    meta: pricesTwcjzqIzWYMeta || {},
    alias: pricesTwcjzqIzWYMeta?.alias || [],
    redirect: pricesTwcjzqIzWYMeta?.redirect || undefined,
    component: () => import("/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/prices.vue").then(m => m.default || m)
  },
  {
    name: profilesQxR08u5LbMeta?.name ?? "profile",
    path: profilesQxR08u5LbMeta?.path ?? "/profile",
    meta: profilesQxR08u5LbMeta || {},
    alias: profilesQxR08u5LbMeta?.alias || [],
    redirect: profilesQxR08u5LbMeta?.redirect || undefined,
    component: () => import("/srv/sites/cryptoxchange/cryptoxchange-nuxt/pages/profile.vue").then(m => m.default || m)
  }
]